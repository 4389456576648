<template>
  <div id="settleearnings">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">结清收益</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- tab分页 -->
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :before-leave="beforeLeave">
        <el-tab-pane label="所有结清收益明细" name="first">
          <div class="downreport" v-if="!isOnlyView">
            <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
          </div>
          <div class="tablebox">
            <el-table stripe :data="tableData" style="width: 100%" border :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                backgroundColor: '#eff3f8',
              }">
              <el-table-column align="center" type="index" label="序" width="50">
                <template slot-scope="scope">
                  <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="车架号" width="200">
                <template slot-scope="scope">
                  <span class="carInfo" @click="SkipProperty(scope.row.id)">
                    {{ scope.row.carframeNo }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="companyName" label="公司"> </el-table-column>
              <el-table-column align="center" prop="carType" label="车型"> </el-table-column>
              <el-table-column align="center" prop="saleDate" label="出售日期 "> </el-table-column>
              <el-table-column align="center" prop="disposedDate" label="处置日期 "> </el-table-column>
              <el-table-column width="100" align="center" prop="createDate" label="创建时间 "> </el-table-column>
              <el-table-column align="center" prop="realIrr" label="实际IRR">
                <template slot-scope="scope">
                  {{scope.row.realIrr == ""  ? "--" : scope.row.realIrr + "%"}}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页区域 -->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
            layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="买断结清" name="second">
          <div class="downreport" v-if="!isOnlyView">
            <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcelByBought()">导出</el-button>
          </div>
          <div class="tablebox">
            <el-table stripe :data="tableData1" style="width: 100%" border :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                backgroundColor: '#eff3f8',
              }">
              <el-table-column align="center" type="index" label="序" width="50">
                <template slot-scope="scope">
                  <span>{{ (pagenum1 - 1) * size1 + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="车架号" width="200">
                <template slot-scope="scope">
                  <span class="carInfo" @click="SkipProperty(scope.row.id)">
                    {{ scope.row.carframeNo }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="companyName" label="公司"> </el-table-column>
              <el-table-column align="center" prop="carType" label="车型"> </el-table-column>
              <el-table-column align="center" prop="saleDate" label="出售日期 "> </el-table-column>
              <el-table-column width="100" align="center" prop="createDate" label="创建时间 "> </el-table-column>
              <el-table-column align="center" prop="realIrr" label="实际IRR">
                <template slot-scope="scope">
                  {{scope.row.realIrr == ""  ? "--" : scope.row.realIrr + "%"}}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页区域 -->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum1" :page-sizes="[1, 5, 10, 20]" :page-size="size1"
            layout="total, sizes, prev, pager, next, jumper" :total="total1"> </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="已处置结清" name="third">
          <div class="downreport" v-if="!isOnlyView">
            <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcelByDisposed()">导出</el-button>
          </div>
          <div class="tablebox">
            <el-table stripe :data="tableData2" style="width: 100%" border :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                backgroundColor: '#eff3f8',
              }">
              <el-table-column align="center" type="index" label="序" width="50">
                <template slot-scope="scope">
                  <span>{{ (pagenum2 - 1) * size2 + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="车架号" width="200">
                <template slot-scope="scope">
                  <span class="carInfo" @click="SkipProperty(scope.row.id)">
                    {{ scope.row.carframeNo }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="companyName" label="公司"> </el-table-column>
              <el-table-column align="center" prop="carType" label="车型"> </el-table-column>
              <el-table-column align="center" prop="disposedDate" label="处置日期 "> </el-table-column>
              <el-table-column width="100" align="center" prop="createDate" label="创建时间 "> </el-table-column>
              <el-table-column align="center" prop="realIrr" label="实际IRR">
                <template slot-scope="scope">
                  {{ scope.row.realIrr == ""  ? "--" : scope.row.realIrr + "%"}}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页区域 -->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum2" :page-sizes="[1, 5, 10, 20]" :page-size="size2"
            layout="total, sizes, prev, pager, next, jumper" :total="total2"> </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="CustoBtn" class="CustoBtn">
          <span slot="label">
            <el-link icon="el-icon-refresh" style="color: #409eff; font-weight: 600" :underline="false">刷新</el-link>
          </span>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { profitList, downProfit, ByBoughtProfitList, ByDisposedProfitList, downProfitByBought,downProfitByDisposed} from "@/api/settle.js";
export default {
  name: "settleearnings",
  data() {
    return {
      tableData: [], //全部结清
      tableData1: [], // 买断结清
      tableData2: [], // 已处置结清

      pagenum: 1,
      size: 10,
      total: 0,

      pagenum1: 1,
      size1: 10,
      total1: 0,

      pagenum2: 1,
      size2: 10,
      total2: 0,
      activeName: "first",
      curName: "first",
      isAxios: true, //防止重复点击
      outTime: null,
    };
  },
  created() {
    this.getdataList();
  },
  destroyed() {
    console.log("离开----------");
  },
  computed: {
    isOnlyView() {
      return this.$store.getters.getOnlyViewRole;
    },
  },
  methods: {
    getdataList(refresh) {
      if (this.isAxios) {
        this.isAxios = false;
        if (this.activeName == "first") {
          profitList(this.pagenum, this.size)
            .then((res) => {
              console.log(res);
              const data = res.data.dataList;
              data.map((res) => {
                res.realIrr = this.irrFormatt(res.realIrr);
                res.saleDate = res.saleDate ? this.$moment(res.saleDate).format("YYYY-MM-DD") : "--";
                res.disposedDate = res.disposedDate ? res.disposedDate : "--";
              });
              this.tableData = res.data.dataList;
              this.total = res.data.total;

              this.isAxios = true;
              if (refresh) {
                this.$message({
                  message: "刷新数据成功",
                  type: "success",
                  duration: 800,
                });
              }
            })
            .catch((err) => {
              this.isAxios = true;
            });
        } else if (this.activeName == "second") {
          ByBoughtProfitList(this.pagenum1, this.size1)
            .then((res) => {
              console.log(res);
              const data = res.data.dataList;
              data.map((res) => {
                res.realIrr = this.irrFormatt(res.realIrr);
                res.saleDate = res.saleDate ? this.$moment(res.saleDate).format("YYYY-MM-DD") : "--";
                res.disposedDate = res.disposedDate ? res.disposedDate : "--";
              });
              this.tableData1 = res.data.dataList;
              this.total1 = res.data.total;

              this.isAxios = true;
              if (refresh) {
                this.$message({
                  message: "刷新数据成功",
                  type: "success",
                  duration: 800,
                });
              }
            })
            .catch((err) => {
              this.isAxios = true;
            });
        } else if (this.activeName == "third") {
          ByDisposedProfitList(this.pagenum2, this.size2)
            .then((res) => {
              console.log(res);
              const data = res.data.dataList;
              data.map((res) => {
                res.realIrr = this.irrFormatt(res.realIrr);
                res.saleDate = res.saleDate ? this.$moment(res.saleDate).format("YYYY-MM-DD") : "--";
                res.disposedDate = res.disposedDate ? res.disposedDate : "--";
              });
              this.tableData2 = res.data.dataList;
              this.total2 = res.data.total;

              this.isAxios = true;
              if (refresh) {
                this.$message({
                  message: "刷新数据成功",
                  type: "success",
                  duration: 800,
                });
              }
            })
            .catch((err) => {
              this.isAxios = true;
            });
        }
      }
    },
    // 监听pagesize改变的事件

    handleSizeChange(newSize) {
      console.log(newSize);

      if (this.activeName == "first") {
        this.size = newSize;
        this.pagenum = 1;
      } else if (this.activeName == "second") {
        this.size1 = newSize;
        this.pagenum1 = 1;
      } else if (this.activeName == "third") {
        this.size2 = newSize;
        this.pagenum2 = 1;
      }

      this.getdataList(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      if (this.activeName == "first") {
        this.pagenum = newPage;
      } else if (this.activeName == "second") {
        this.pagenum1 = newPage;
      } else if (this.activeName == "third") {
        this.pagenum2 = newPage;
      }

      this.getdataList(); // 发起数据请求
    },
    // 保留两位小数-补零
    irrFormatt(num) {
      console.log(num);
      if (num === 0) return "0.00";
      if (num == null || num == "") return "";
      var value = Math.floor(parseFloat(num) * 100) / 100;
      var s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },

    // 导出全部报表
    DownExcel() {
      var _this = this;
      downProfit()
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "earnings_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //导出已买断报表
    DownExcelByBought() {
      var _this = this;
      downProfitByBought()
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "earnings_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },

     //导出已处置报表
    DownExcelByDisposed() {
      var _this = this;
      downProfitByDisposed()
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "earnings_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 跳转资产详情
    SkipProperty(id) {
      this.$router.push({
        name: "property",
        params: {
          id,
          assetsId: id,
        },
      });
    },

    // 点击Tab选项卡
    handleClick(tab, event) {
      console.log(tab.name, event, this.activeName);
      if (tab.name != this.curName) {
        console.log("发起网络请求");
        // 判断列表是否为空,不空就不再请求新数据
        if ((this.activeName == "first" && this.tableData.length == 0) || (this.activeName == "second" && this.tableData1.length == 0) || (this.activeName == "third" && this.tableData2.length == 0)) {
          this.getdataList();
        }
      }

      this.curName = tab.name;
    },
    //拦截点击刷新数据切换tab
    beforeLeave(tabName) {
      if (tabName == "CustoBtn") {
        this.refresh();
        return false;
      }
    },

    //刷新最新数据
    refresh() {
      this.refresh;
      if (this.activeName == "first") {
        this.pagenum = 1;
        this.size = 10;
      } else if (this.activeName == "second") {
        this.pagenum1 = 1;
        this.size1 = 10;
      } else if (this.activeName == "third") {
        this.pagenum2 = 1;
        this.size2 = 10;
      }

      //防抖--点击
      if (this.outTime != null) {
        clearTimeout(this.outTime);
      }
      this.outTime = setTimeout(() => {
        this.getdataList("refresh");
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
#settleearnings {
  padding: 60px 20px 20px 20px;

  /deep/ .el-divider__text.is-left {
    font-weight: 900;
    font-size: 16px;
  }

  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }

  /*悬浮样式*/
  ::v-deep .el-tabs__item:hover {
    color: black;
    background-color: #eff3f8;
  }

  /*选中样式*/
  ::v-deep .el-tabs__item.is-active {
    color: black;
    font-weight: 700;
  }

  .downreport {
    margin-bottom: 20px;
  }
  .tablebox {
    width: 80%;

    .carInfo {
      color: #337ab7;
      cursor: pointer;
    }

    .carInfo:hover {
      color: #23527c;
    }
  }
}
</style>
